.heading-section {
  height: 100vh;
  background-image: url("./../images/construction-workers.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0px -200px; */
}
.homepage-altcol-1 {
  background-color: rgb(224, 224, 224) !important;
}
.homepage-altcol-2 {
  background-color: rgb(245, 244, 244) !important;
}
.hero-title h2,
.hero-title h6 {
  color: white !important;
}
.store-icon img {
  margin-right: 0px;
}
.first-section-logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.download-btn {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 48px;
}
.faq-content li {
  margin: 12px 0px 12px 0px;
  list-style: disc;
}
.about-container {
  align-items: center;
}
.hero-title {
  text-align: center;
  margin: 5px 0px 40px 0px;
}
.hero-title .description {
  text-align: center;
  align-self: center;
  flex-wrap: wrap;
  font-weight: 600;
}
.home-container {
  justify-content: center;
  height: 75vh;
  width: 100%;
  margin: -48px auto auto auto;
}

.tons-more-features li:hover {
  cursor: pointer;
  font-weight: 600;
}
.bulk-thumb img {
  max-height: 430px;
  border-radius: 18px;
  box-shadow: 0px 0px 25px rgb(199, 199, 199);
}

.terms-conditions {
  position: absolute;
  right: 10px;
  margin-top: 14px;
  font-size: 14px;
}
.terms-conditions:hover {
  color: rgb(186, 185, 185);
  cursor: pointer;
}

/* --------- Sites --------- */

.site-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
}
.site-card {
  border: 2px solid rgb(201, 201, 201);
  background-color: rgb(245, 244, 244);
  border-radius: 18px;
  padding: 10px;
  max-width: 32%;
  min-width: 340px;

  position: relative; /* Ensure positioning context for the image zoom effect */
  overflow: hidden; /* Hide overflow to prevent zoomed image from spilling outside */
  transition: box-shadow 0.3s ease; /* Optional: Smooth transition effect for the card */
}
.site-card img {
  display: block; /* Removes any extra space below the image */
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.5s ease; /* Smooth zoom effect */
}

.site-card:hover img {
  transform: scale(1.09); /* Zooms in the image by 10% */
}

.site-card:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}
.site-card-text {
  margin: 10px;
}
.site-card-text h3 {
  margin: 16px;
}

.site-card-text p {
  margin: 16px;
}

.site-card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 18px;
}
/* --------------------------- */

#refreshButton {
  border: none;
  margin-bottom: 20px;
}

/* Small Screen */
@media only screen and (max-width: 500px) {
  .heading-section {
    height: unset;
  }
  .hero-title div {
    flex-direction: column;
  }
  .about-container {
    flex-direction: column;
    align-items: center;
  }
  .about-container .faq-content {
    margin-left: 20px;
  }
  .about-container img {
    margin: 0px !important;
  }
  .login-button {
    margin-bottom: 10px;
  }
  .header-fixed {
    width: 95% !important;
    margin: 4px;
    top: 10px;
  }
  .col-lg-6 {
    margin-bottom: 20px;
  }
  .contact-container {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
  .home-container {
    height: unset;
  }
}
