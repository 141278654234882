.campaign-home {
    padding-top: 8px;
    background-color: #f2f2f2;
    /* background-color: rgb(228, 228, 228); */
    /* height: 99vh; */
}

/*----------------------LEFT----------------------*/
.campaign-home-header {
    display: flex;
}

.campaign-dropdown {
    font-size: 0.9vw;
    cursor: pointer;
}



/*----------------------MIDDLE /*----------------------*/
.campaign-chips .k-chip-list-md {
    gap: 10px
}

.campaign-chips .k-chip {
    margin-top: 6px;
    padding: 10px 5px 10px 5px;
    border-radius: 8px;
    border: solid 2px rgb(168, 168, 168);
    background-color: white;
}
.k-chip-solid-base.k-selected {
    background-color: #e1e0e0 !important;
}

.campaign-chips .k-chip-content {
    font-weight: 700;
    font-size: 40vw;
}

.campaign-home-middle-content {
    width: 75%;
    /* height: 90.5vh; */
    overflow-y: hidden;
    overflow: auto;
    /* padding: 30px; */

    margin: 0px auto 0px auto;
    display: 'flex';
    justify-content: center;
    align-content: center;
    align-self: center;
    flex-wrap: wrap;

    /* border: solid 2px rgb(168, 168, 168); */
}

.campaign-dropdown-item {
    font-size: 0.8vw;
}

.campaign-search {
    text-align: center;
    align-self: center;

    margin: 4px 0px 0px auto;

    /* width: 90%; */
    /* width:auto; */
    font-weight: 600;
    background-color: white;
    border: solid 2px rgb(180, 180, 180);
    padding: 5px 5vw 5px 5vw;
    border-radius: 20px;
    cursor: pointer;
}

.add-new-campaign-button {
    /* margin: 0px 50px 20px 50px; */
    min-width: 70px;
    margin: 0px 0px auto auto;
    padding: 8px;
    text-align: center;
    font-weight: 700;
    color: white;
    background-color: #063bb5;

    border-radius: 10px;

    /* font-size: 1.8vh; */
}

.add-new-campaign-button:hover {
    cursor: pointer;
    background-color: #052d89;
}

.settings-gear {
    margin: 0px 10px auto 20px;
}

.k-chip-content span {
    padding: 2px;
    font-size: 0.9vw;
}

/* -------------------Campaign Card CSS------------------- */
.k-card-body {
    padding: 0px;
}

.campaign-card {
    /* background-color: rgb(231, 231, 231); */
    /* background-color: rgb(180, 180, 181); */
}

.campaign-card:hover {
    cursor: pointer;
    filter: brightness(97%);
}

.campaign-card-content {
    border-radius: 5px;
    margin: 10px 20px 0px 20px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Hiding Scrollbar  */
/* Hide scrollbar for Chrome, Safari and Opera */
.campaign-home-middle-content::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.campaign-home-middle-content {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* For View Sites */
/* Hide scrollbar for IE, Edge and Firefox */
.k-card-body div {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
/* Hide scrollbar for IE, Edge and Firefox */
.campaign-overview-container div {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* For Campaign Overview */
/* Hide scrollbar for IE, Edge and Firefox */
.campaign-overview-container {

    /* height: 30vmax !important; */
    display: flex;
    justify-content: center;
    overflow: auto;
    padding: 30px;

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* For add-new-campaign-plan-cards */
/* Hide scrollbar for IE, Edge and Firefox */
.add-new-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}



/* -------------------Add new campaign------------------- */
.add-new-container {
    /* height: 85vh; */
    height: 80vmin; /* has to be */
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* overflow: auto; */
    /* background-color: #ffffff; */
}

.site-chosen {
    z-index: 1;
    position: absolute;
    height: fit-content;

    bottom: 225px;

    padding: 20px;
    border-radius: 8px;
    font-weight: 600;
    background-color: rgb(225, 224, 224);
    border: solid 2px rgb(88, 88, 88);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1);
}

.plan-card-description {
    font-size: 0.7vw;
    color: rgb(54, 54, 54);
}


.campaign-overview-divider {
    align-self: center;
    width: 80%;
    border: solid 2px rgb(247, 247, 247);
    margin: 35px 0px 10px 0px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, .1);
}

.campaign-overview-vertical-divider {
    /* margin: 1vw 4vw 1vw 4vw  !important; */
    margin: 5vmin !important;
    border: solid 2px rgb(202, 202, 202);
    border-radius: 12px;
    margin: 55px 0px 30px 0px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, .1);
    height: 100%;
}

.add-new-campaign-plan-cards {
    display: flex;
    margin: auto;
    gap: 35px;
    text-align: center;
    justify-content: center;
}

.add-new-campaign-stepper-buttons {
    display: flex;
    justify-content: space-around;
    width: 100vmax;
    margin: 0px 0px 10px 0px;
}

.add-campaign-map {
    display: flex;
    justify-content: center;
    margin: auto 0px auto 0px;
    gap: 30px;
    height: 60vmin;
}

.payment-container {
    display: flex;
    flex-direction: column;
    /* height: auto; */
    width: 45%;
}

.payment-comp {
    justify-content: space-between;
    padding: 6px;
}
.payment-comp input{
    display: none;
}
.overview-box-containers{
    display: flex;
    flex-direction: column;
    height: auto;
}

/* -------------------Settings------------------- */
.settings-gear:hover {
    cursor: pointer;
    color: rgb(143, 143, 143) !important;
}

.edit-payment-container {
    /* background-color: gray; */
    /* height: 90vh; */
    margin: auto;
}

.settings-data {
    padding: 25px;
    color: #787878;
    background-color: #fcf7f8;
    border: 1px solid rgba(0, 0, 0, .05);
    border-radius: 5px;
}

.settings-data button {
    margin-left: -2px;
    padding: 25px;
    margin-bottom: 10px;
    font-weight: 600;
}

.sign-out:hover {
    cursor: pointer;
    font-weight: 700 !important;
}

/* -------------------Campaign Card dialog------------------- */
.campaign-card-dialog {
    width: 60vmin;
    background-color: rgb(235, 235, 235);
    padding: 20px;
    border: solid 2px rgb(180, 180, 180);
    border-radius: 5px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1);
}


/* -----------Campaign Sites----------- */
.sites-list button {
    border-radius: 6px;
    font-weight: 600;
    padding: 15px;

    transition: height 0.3s !important;
}

.sites-list button span {
    width: 100%;
}

.sites-list:hover button {
    height: 14vh !important;
}


.clicked-site-div {
    padding: 10px;
    margin-top: 15px;

    display: none;
}


.sites-list:hover .clicked-site-div {
    display: flex !important;
    flex-direction: column;
}

/* ------------------------------------ */
.hamburger-container {
    display: none;
}

.hamburger-dropdown {
    position: absolute;
    top: 80px;
    left: 60px;
    z-index: 2;
    background-color: white;
    border-radius: 5px;
    border: 2px solid rgb(239, 239, 239);
    padding: 5px;
}

.hamburger-dropdown li {
    border-radius: 2px;

    margin: 10px;
    border: 2px solid rgb(239, 239, 239);
}

.hamburger-icon {
    margin: 10px 10px 0px 10px;
    cursor: pointer;
    color: #787878;
}

.view-sites-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
    gap: 40px;
}
.campaign-data{
    display: flex;
    justify-content: center;
    margin: auto 0px auto 0px;
}
.campaign-data .k-card{
    padding: 30px !important;
}
.overview-start-end-date{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0px 14px 0px;
}
/* Small Screen */
@media only screen and (max-width: 700px) {
    .overview-box-containers div{
        font-size: 1.3vh !important;
    }
    .campaign-home-middle-content {
        width: 90%;
    }
    .campaign-chips {
        display: none !important;
    }
    .view-sites-map{
        margin: auto auto 15px auto
    }

    .hamburger-container {
        display: block;
        font-weight: 500;
    }

    .hamburger-container li:hover {
        cursor: pointer;
        background-color: #f2f2f2;
    }

    .campaign-search {
        margin: 13px 0px 0px 0px;
        padding: 6px 34px 6px 34px;
    }

    .add-new-campaign-button {
        margin-top: 3px;
        margin-right: 4px;
        padding: 8px;
    }

    .settings-gear {
        margin: 2px 0px 0px 2px;
    }

    .view-sites-container {
        flex-direction: column;
    }

    .add-new-container {
        height: fit-content;
        /* height: 110vh; */
        /* height: 79vmax; */
    }

    .add-new-campaign-plan-cards {
        margin: 20px 0px 20px 0px;
        flex-direction: column;
    }

    .add-new-campaign-stepper-buttons {
        justify-content: space-between !important;
        width: 90vmin;
    }

    .add-campaign-map {
        margin:20px 0px 20px 0px;
        height: 60vmax;
    }

    .campaign-overview-container div {
        padding: 4px;
        flex-direction: column;
        text-align: center;
        align-items: center;
        font-size: 1.4vh;
    }

    .payment-container {
        width: 75vw;
    }

    .payment-comp {
        justify-content: space-around;
    }

    .campaign-overview-vertical-divider {
        width: 90%;
    }

    .campaign-overview-divider {
        display: none;
    }

    .overview-map-container div {
        width: 80vw !important;
        height: 40vh !important;
    }
    .overview-map-container .gmnoprint{
        display: none;
    }
    .campaign-data{
        margin: 10vh 0px 10vh 0px;
    }
    .campaign-data .k-card{
        padding: 10px !important;
    }
    .overview-card-container{
        width: 95% !important;
    }
    .add-new-container {
        /* height: 75vh; */
    }
    .overview-start-end-date h6{
        margin-top: 4px;
        font-size: larger !important
    }
}