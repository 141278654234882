.about .wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  animation: fadeIn 1000ms ease;
  -webkit-animation: fadeIn 1000ms ease;
}
.about .container-1 h1 {
  font-size: 64px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
  line-height: 1;
  font-weight: 700;
  text-align: center;
}

.about .container-1.dot {
  color: var(--secondary-color);
}
