.card-title {
    font-weight: 600;
    padding-top: 10px;
}

.login-card {
    width: 25%;
    height: 60vmin;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1);
    padding: 30px;
}

.sign-up-card {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1);
    padding: 30px;
}

.login-container {
    background-color: rgb(250, 250, 250);
    width: 630px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1);
    padding: 20px;
}

.login-cards-container {
    display: flex;
    flex-direction: row;
    gap: 35px;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 10px;
}

.pass-icon {
    position: absolute;
    bottom: 235px;
    right: 30px;
}


/* Vision Icons */
.signup-pass-icon {
    position: absolute;
    bottom: 295px;
    right: 40px;
}

.signup-retype-pass-icon {
    position: absolute;
    bottom: 38px;
    right: 40px;
}

.signup-pass-icon:hover,
.signup-retype-pass-icon:hover,
.pass-icon:hover,
.reset-pass-icon:hover {
    cursor: pointer;
    color: rgb(127, 127, 127) !important;
}

.reset-pass-icon {
    position: absolute;
    bottom: 396px;
    right: 45px;
}

.reset-retype-pass-icon {
    position: absolute;
    bottom: 138px;
    right: 45px;
}

/* +++ */
.business-details-pass-icon {
    position: absolute;
    bottom: 128px;
    right: 45px;
}

.business-details-retype-pass-icon {
    position: absolute;
    bottom: 44px;
    right: 45px;
}

.business-details-retype-pass-icon:hover,
.business-details-pass-icon:hover,
.edit-profile-pass-icon:hover {
    cursor: pointer;
    color: rgb(127, 127, 127) !important;
}

.edit-profile-pass-icon {
    position: absolute;
    bottom: 105px;
    right: 30px;
}

/* -------------------- */




.forgot-password,
.back-button,
.reset-code {
    color: rgb(91, 90, 90);
    font-weight: 500;
}

.forgot-password:hover,
.back-button:hover,
.reset-code:hover {
    color: rgb(64, 64, 64);
    cursor: pointer;
}

.sign-up {
    text-align: center;
}

.sign-up span {
    text-decoration: underline;
    cursor: pointer;
}

.password-checklist {
    margin-top: 15px;
}

.sign-up-fields-beside .k-form-field {
    /*The fields that are in a row have a width that fill the form*/
    width: 48%;
}

/* Medium Screen */
@media only screen and (min-width: 501px) and (max-width: 800px) {
    .login-cards-container {
        flex-direction: column !important;
        overflow: auto !important;
    }

    .login-cards-container .k-card {
        width: 80%;
        height: fit-content;
    }

    /* Login */
    .plan-card-description {
        font-size: 2vw !important;
    }

    .footer-bottom .row p{
        display: none !important;
    }

    .login-container {
        width: 90%;
    }
}


/* Small Screen */
@media only screen and (max-width: 500px) {
    .footer-bottom .footer-widget ul {
        justify-content: center !important;
    }

    /* Login */
    .plan-card-description {
        font-size: unset;
    }

    .login-container {
        width: 90%;
    }

    .login-card {
        width: unset;
        height: 100%;
    }

    .login-cards-container {
        /* height: 100vh; */
        flex-direction: column !important;
        overflow: auto !important;
    }

    .plan-card-description {
        font-size: 2.5vw !important;
    }

    .sign-up-stepper-buttons {
        width: 100% !important;
    }

    .footer-bottom .row .col-lg-8 {
        margin: 7px !important;
        font-size: medium;
    }
}