.latest-posts .k-card {
  transition: box-shadow 0.3s ease-in-out;
}

.latest-posts .k-card:hover {
  cursor: pointer;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 1);
}

.latest-posts .k-card img {
  transition: box-shadow 0.3s ease-in-out;
}
.latest-posts .k-card:hover img {
  transform: scale(1.1); /* Zoom in by 10% */
}
